<template>
  <section class="mt-2">
    <b-overlay :show="loading" rounded="sm">
      <b-tabs
        v-model="currentTab"
        content-class="mt-2"
        @activate-tab="activatedTab"
      >
        <b-tab :title="$t('LICENSE.TITLE')">
          <b-form-group label-cols-md="3" :label="$t('DESCRIPTION')">
            <b-form-input trim
              v-model="prices.description"
              class="w-150"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3" :label="$t('LICENSE.CURRENCY')">
            <b-form-input trim
              v-model="prices.currency"
              class="w-150"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3" :label="$t('LICENSE.TITLE')">
            <b-form-input trim
              v-model="prices.license"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ prices.currency }} {{ $t("PER_MONTH") }}
          </b-form-group>

          <b-form-group
            label-cols-md="3"
            :label="$t('LICENSE.INCLUDED_ACCOUNTS')"
          >
            <b-form-input trim
              v-model="prices.included_accounts"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ $t("EACH") }}
          </b-form-group>

          <b-form-group label-cols-md="3" :label="$t('LICENSE.ACCOUNTS')">
            <b-form-input trim
              v-model="prices.accounts"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ prices.currency }} {{ $t("EACH_PER_MONTH") }}
          </b-form-group>
        </b-tab>
        <b-tab :title="$t('BILLING.AUTHENTICATIONS')">
          <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.freja')">
            <b-form-input trim
              v-model="prices.login_freja"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ prices.currency }} {{ $t("EACH_UNIQUE") }}
          </b-form-group>

          <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.freja-org')">
            <b-form-input trim
              v-model="prices.login_freja_org"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ prices.currency }} {{ $t("EACH_UNIQUE") }}
          </b-form-group>

          <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.bankid-se')">
            <b-form-input trim
              v-model="prices.login_bankid_se"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ prices.currency }} {{ $t("EACH") }}
          </b-form-group>
        </b-tab>

        <b-tab :title="$t('BILLING.VERIFICATIONS')">
          <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.freja')">
            <b-form-input trim
              v-model="prices.verify_freja"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ prices.currency }} {{ $t("EACH_UNIQUE") }}
          </b-form-group>

          <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.bankid-se')">
            <b-form-input trim
              v-model="prices.verify_bankid_se"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ prices.currency }} {{ $t("EACH") }}
          </b-form-group>

          <b-form-group label-cols-md="3" :label="$t('AUTH_METHODS.sms')">
            <b-form-input trim
              v-model="prices.verify_sms"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ prices.currency }} {{ $t("EACH") }}
          </b-form-group>
        </b-tab>
      </b-tabs>

      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="editBtn">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      defaultPrices: null,
      information: this.organisation,
      show: false,
      prices: this.organisation.prices,
      loading: false,
      return_number: true,
      currentTab: 0
    };
  },
  methods: {
    activatedTab(){
      if(this.isChanged)
      {
        let self = this;
        this.$bvModal
          .msgBoxConfirm(this.$t("SAVE_CHANGES"), {
            size: "sm",
            buttonSize: "sm",
            okVariant: "primary",
            okTitle: this.$t("YES"),
            cancelTitle: this.$t("NO"),
            bodyClass: "messagebox",
            footerClass: "p-2  text-center messagebox",
            hideHeaderClose: false,
            centered: true,
          })
          .then(function(value) {
            if (value) {              
              self.editBtn();
            }else{              
              self.prices = JSON.parse(JSON.stringify(self.defaultPrices));          
              self.$emit("updatedPrice", self.defaultPrices);
            }
          })
          .catch(function() {});        
      }
    },
    editBtn: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(
          this.user.hostname +
            "/administration/organisation/edit-price",
          this.prices
        )
        .then(function(result) {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
          self.defaultPrices = JSON.parse(JSON.stringify(result.data));          
          self.$emit("updatedPrice", result.data);

        })
        .catch(function() {
        });
    },
    changedPrices: function(prices) {
      this.organisation.prices = prices;
    },
  },
  computed: {
    isChanged(){
      if(this.defaultPrices != null)
      {
        let check1 = JSON.parse(JSON.stringify(this.defaultPrices));
        let check2 = JSON.parse(JSON.stringify(this.prices));
        delete check1.created_date;
        delete check2.created_date;
        delete check1.id;
        delete check2.id;
        return JSON.stringify(check1) != JSON.stringify(check2);
      }
      return false;
    }
  },
  mounted: function() {
    this.defaultPrices = JSON.parse(JSON.stringify(this.prices));
  },
};
</script>
<style></style>
